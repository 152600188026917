// import { Outlet} from 'react-router-dom';
// import { HashLink } from 'react-router-hash-link';
import '../stylesheets/Header.css'
import { useState } from 'react';

const Header = (props) => {
    const [animated, setAnimated] = useState(false);
    const [hideName, setHideName] = useState(false);
    const [showResearcher, setShowResearcher] = useState(false);

    const toggleNav = () => {
        setAnimated(!animated);
    }

    const toggleDev = () => {
        setHideName(!hideName);
    }

    const toggleResearcher = () => {
        setShowResearcher(!showResearcher);
    }

    return <>
        <div className="nav-wrapper">
            <div className="nav-wrapper-left">
                <div className="nav-wrapper-left-wrap">
                    <div className="brand">
                        {/* the CSS class primary-text & alt-text come from Landing.css */}
                        <div onClick={toggleDev} className={"primary-text alt-text " + props.color}>dev</div>
                        <div className={"primary-text " + (hideName ? '' : ' hide')}>eloper</div>
                        <div onClick={toggleResearcher} className={"primary-text"}>an</div>
                        <div className={"primary-text " + (showResearcher ? '' : ' hide')}>ewresearcher</div>
                    </div>
                    <button className="nav-toggle" onClick={toggleNav}><img src="/images/menu.svg" alt="menu" /></button>
                </div>
            </div>
            
            <div className={'nav-bar ' + (animated ? 'animated ' : '')}>
                {/* <HashLink to="/#landing">Me</HashLink>
                <HashLink to="/#featured">Highlights</HashLink>
                <HashLink to="/#projects">All Work</HashLink>
                <HashLink to="/#timeline">Timeline</HashLink> */}
                <a href="/#landing">Me</a>
                <a href="/#featured">Highlights</a>
                <a href="/#projects">All Work</a>
                <a href="/#timeline">Timeline</a>
                {/* <Link>Blog</Link> */}
            </div>
        </div>
    </>;
}

export default Header;