import '../stylesheets/Landing.css'
import TagCard from './TagCard.js'
// import { Link } from 'react-router-dom';
import '../stylesheets/Contact.css'

const Landing = () => {

    const tags = ['Soccer', 'Volleyball', 'Weightlifting', 'Badminton', 'Boxing', 'Squash']
    const email = "devansrinivasan13@gmail.com"

    return <>
        <section id='landing'>
            <div className="wrapper">
                <div className="shadow-bg">
                    <div className="landing-content">
                        <div className='left-content'>
                            <h1 className="primary-text">Devan Srinivasan </h1>
                            <h6 className="alt-text purple">DEVELOPER</h6>
                            <p className='landing-body primary-text'>
                                I am a Masters Student at the University of Toronto researching in NLP and Computational Linguistics. I love mathematics and problem solving
                                which has led me to explore projects in just about every discipline (see below). In my free time I am currently building a social media app and 
                                developing my own algorithmic trading.
                                {/* Based in Toronto, Canada I have been programming since I was 12. I love to solve problems that challenge me 
                                and love to build creatively. That's why programming and mathematics have been a long-lasting passion for me as the field is full of things to tackle and learn. I strive to keep 
                                my mind open and aware, and my heart hungry to learn and grow. */}
                            </p>
                            <p className='landing-body primary-text'>The multicolor periodic table below is inspired by my mother, a chemical engineer, who encouraged me to pursue science.</p>
                            <h2 className='landing-body'>
                                <span className="alt-text purple">HOBBIES: </span>
                            </h2>
                            <div className="tags">
                                {tags.map((tag, index) => {
                                    let props = {
                                        title: tag,
                                        color: "purple",
                                    }
                                    return <TagCard  key={index} {...props}/>
                                })}
                            </div>
                            <h2 className='landing-body'>
                                <span className="alt-text purple">CONNECT WITH ME</span>
                            </h2>
                            <div className="contact-wrapper">
                                {/* <Link to="https://www.linkedin.com/in/devan-srinivasan-ba0058156/"><img src="/images/linkedin_purple_128.png" alt="linkedin logo"/></Link>
                                <Link to="https://github.com/devan-srinivasan"><img src="/images/github_logo.png" alt="github logo"/></Link>
                                <Link to={"mailto:" + email}><img src="/images/email_purple_128.png" alt="email logo"/></Link>
                                <Link to="route" onClick={(event) => { event.preventDefault(); window.open("/files/Resume.pdf"); }}>
                                    <img src="/images/resume_purple_128.png" alt="resume logo"/>
                                </Link> */}
                                <a href="https://www.linkedin.com/in/devan-srinivasan-ba0058156/"><img src="/images/linkedin_purple_128.png" alt="linkedin logo"/></a>
                                <a href="https://github.com/devan-srinivasan"><img src="/images/github_logo.png" alt="github logo"/></a>
                                <a href={"mailto:" + email}><img src="/images/email_purple_128.png" alt="email logo"/></a>
                                <button onClick={(event) => { event.preventDefault(); window.open("/files/Resume.pdf"); }}>
                                    <img src="/images/resume_purple_128.png" alt="resume logo"/>
                                </button>
                            </div>
                        </div>
                        <img id='profile' src="./images/profile.png" alt='profile'></img>
                    </div>
                </div>
                {/* <div className="boni-wrapper">
                    <div className="dogSymb-wrapper"><span className="dogSymb">cute dog </span><img src="/images/spin_down_arrow.png" alt="curly arrow"/></div>
                    <img src="/images/boni.png" alt="boni" />
                </div> */}
            </div>
        </section>
    </>;
}

export default Landing;